<template>
  <div class="panel-list panel-list-new">
        <div class="row review-component">
            <b-card class="col-lg-12 main-content" v-cloak>
                <div class="panel-list">
                  <div class="panel-body">
                    <b-row class="panel-title">
                      <label class="col-lg-6 Intercompany-Agency">EMPLOYEE/REQUEST TYPE INFO </label>
                    </b-row>
                    <b-row class="panel-content">
                      <b-col cols="lg-4">
                        <label>EMPLOYEE PHONE NUMBER</label>
                        <p v-if="!Request.EmployeePhoneNumber">
                          N/A
                        </p>
                        <p>{{ Request.EmployeePhoneNumber }}</p>
                      </b-col>
                      <b-col cols="lg-4">
                        <label>AGENCY</label>
                        <p>
                          {{ Request.EmployeeAgency }}
                        </p>
                      </b-col>
                      <b-col cols="lg-4">
                        <label>REQUEST TYPE</label>
                        <p>{{ Request.RequestType }}</p>
                      </b-col>
                      <b-col cols="lg-4">
                        <label>Type of change requested</label>
                        <p v-if="Request.ChangeType == 'Both'">Address & Banking</p>
                        <p v-else>{{ Request.ChangeType }}</p>
                      </b-col>
                    </b-row>

                  </div>
                  <div class="panel-body">
                    <b-row class="panel-title">
                      <label class="col-lg-6 Intercompany-Agency">SETUP INFO </label>
                    </b-row>
                    <b-row class="panel-content">
                      <b-col cols="lg-4">
                        <label>VENDOR CODE </label>
                        <p>{{ Request.VendorCodeOptions }}</p>
                      </b-col>
                      <b-col cols="lg-4">
                        <label>VENDOR BUSINESS NAME </label>
                        <p>{{ Request.VendorBusinessName }}</p>
                      </b-col>
                      <b-col cols="lg-4">
                        <label>Invoice Attachment</label>
                        <upload-file-holder :Files="Request.InvoiceAttachment"></upload-file-holder>
                      </b-col>
                      <b-col cols="lg-4" v-if="this.Request.ChangeType == 'Both' ||  this.Request.ChangeType == 'Address'">
                        <label>COUNTRY</label>
                        <p>{{ Request.RemittanceCountry }}</p>
                      </b-col>
                      <b-col cols="lg-4" v-if="this.Request.ChangeType == 'Both' ||  this.Request.ChangeType == 'Address'">
                        <label>STREET ADDRESS LINE 1</label>
                        <p>{{ Request.StreetAddressLine1 }}</p>
                      </b-col>
                      <b-col cols="lg-4" v-if="this.Request.ChangeType == 'Both' ||  this.Request.ChangeType == 'Address'">
                        <label>STREET ADDRESS LINE 2</label>
                        <p>{{ Request.StreetAddressLine2 }}</p>
                      </b-col>
                      <b-col cols="lg-4" v-if="this.Request.RemittanceCity">
                        <label>CITY</label>
                        <p>{{ Request.RemittanceCity }}</p>
                      </b-col>
                      <b-col cols="lg-4" v-if="this.Request.RemittanceState">
                        <label>STATE</label>
                        <p>{{ Request.RemittanceState }}</p>
                      </b-col>
                      <b-col cols="lg-4" v-if="this.Request.RemittanceZipCode">
                        <label>ZIP CODE</label>
                        <p>{{ Request.RemittanceZipCode }}</p>
                      </b-col>
                      <b-col cols="lg-4">
                        <label>ACCOUNTING CONTACT NAME</label>
                        <p>{{ Request.AccountingContactName }}</p>
                      </b-col>
                      <b-col cols="lg-4">
                        <label>ACCOUNTING CONTACT TITLE </label>
                        <p>{{ Request.AccountingTitle }}</p>
                      </b-col>
                      <b-col cols="lg-4">
                        <label>ACCOUNTING PHONE # </label>
                        <p>{{ Request.AccountingPhone }}</p>
                      </b-col>
                      <b-col cols="lg-4">
                        <label>ACCOUNTING EMAIL ADDRESS</label>
                        <p>{{ Request.AccountingEmailAddress }}</p>
                      </b-col>
                    </b-row>
                      <b-row class="panel-content">
                        <b-col cols="lg-12">
                          <label>Who at the vendor provided this information?</label>
                        </b-col>
                        <b-col cols="lg-4">
                          <label>Name</label>
                          <p>{{ Request.InfoFromName }}</p>
                        </b-col>
                        <b-col cols="lg-4">
                          <label>Title</label>
                          <p>{{ Request.InfoFromTitle }}</p>
                        </b-col>
                        <b-col cols="lg-4">
                          <label>Phone</label>
                          <p>{{ Request.InfoFromPhone }}</p>
                        </b-col>
                      </b-row>
                      <b-row class="panel-content">
                        <b-col cols="lg-4">
                          <label>How was this information obtained?</label>
                          <p>
                            <span v-if="Request.InfoFromSourceIsVerbal">Verbal</span>
                            <span
                              v-if="Request.InfoFromSourceIsVerbal && Request.InfoFromSourceIsWritten">
                              &
                            </span>
                            <span v-if="Request.InfoFromSourceIsWritten">Written</span>
                          </p>
                        </b-col>

                        <b-col cols="lg-4" v-if="Request.InfoFromSourceVerbal">
                          <label>Verbal</label>
                          <p>
                            {{ Request.InfoFromSourceVerbal }}
                          </p>
                        </b-col>
                        <b-col cols="lg-4" v-if="Request.InfoFromSourceWritten">
                          <label>Written</label>
                          <p>
                            {{ Request.InfoFromSourceWritten }}
                          </p>
                        </b-col>
                        <b-col cols="lg-4" v-if="Request.InfoFromSourceWritten">
                          <label>Written Communication File</label>
                          <upload-file-holder :Files="Request.SetupInfoSource"></upload-file-holder>
                        </b-col>

                      </b-row>
                      <b-row class="panel-content">
                      <b-col cols="lg-4">
                        <label>DID THE VENDOR PROVIDE A CURRENT W9?</label>
                        <p v-if="Request.CurrentW9Attachment">YES</p>
                        <p v-else-if="!Request.CurrentW9Attachment">NO</p>
                      </b-col>
                      <b-col :cols="ColumnsClass" v-if="this.HasW9Attachment">
                        <label>IDENTIFICATION Type</label>
                        <p v-if="Request.USTaxID">US Tax ID </p>
                        <p v-if="Request.Foreign">Foreign</p>
                        <p v-if="Request.SocialSecurityNumber">SOCIAL SECURITY NUMBER</p>
                      </b-col>
                      <b-col :cols="ColumnsClass" v-if="this.HasW9Attachment">
                        <label>IDENTIFICATION NUMBER</label>
                        <p v-if="Request.USTaxID">{{ formatIdentityType(Request.USTaxID) }}</p>
                        <p v-if="Request.Foreign">{{ formatIdentityType(Request.Foreign) }}</p>
                        <p v-if="Request.SocialSecurityNumber">{{ formatIdentityType(Request.SocialSecurityNumber) }}</p>
                      </b-col>
                      <b-col cols="lg-4" v-if="Request.IdentificationFiles != null && this.HasW9Attachment">
                        <label>Attachment : {{ AttachmentLabel }}</label>
                        <upload-file-holder :Files="Request.IdentificationFiles"></upload-file-holder>
                      </b-col>
                    </b-row>
                  </div>

                  <div class="panel-body" v-if="Request.ChangeType == 'Both' || Request.ChangeType == 'Banking'">
                    <b-row class="panel-title">
                      <label class="col-lg-6 Intercompany-Agency">BANKING INFO</label>
                    </b-row>
                    <b-row class="panel-content">
                      <b-col cols="lg-4">
                        <label>BANK NAME</label>
                        <p>{{ Request.BankName }}</p>
                      </b-col>
                      <b-col cols="lg-4">
                        <label>BANK ADDRESS LINE 1</label>
                        <p>{{ Request.BankAddressLine1 }}</p>
                      </b-col>
                      <b-col cols="lg-4">
                        <label>BANK ADDRESS LINE 2</label>
                        <p v-if="!Request.BankAddressLine2">N/A</p>
                        <p v-else>{{ Request.BankAddressLine2 }}</p>
                      </b-col>
                      <b-col cols="lg-4">
                        <label>COUNTRY</label>
                        <p>{{ Request.BankCountry }}</p>
                      </b-col>
                      <b-col cols="lg-4" v-if="Request.SelectedStateName">
                        <label>STATE</label>
                        <p>{{ Request.SelectedStateName }}</p>
                      </b-col>
                      <b-col cols="lg-4" v-if="Request.BankCity">
                        <label>CITY</label>
                        <p>{{ Request.BankCity }}</p>
                      </b-col>
                      <b-col cols="lg-4" v-if="Request.BankZipCode">
                        <label>ZIP CODE</label>
                        <p>{{ Request.BankCity }}</p>
                      </b-col>
                      <b-col cols="lg-4">
                        <label>BANK ACCOUNT NAME</label>
                        <p>{{ Request.AccountName }}</p>
                      </b-col>
                      <b-col cols="lg-4">
                        <label>BANK ACCOUNT NUMBER</label>
                        <p>{{ Request.AccountNumber }}</p>
                      </b-col>
                      <b-col cols="lg-4" v-if="Request.RoutingNumber">
                        <label>ROUTING NUMBER (US ONLY)</label>
                        <p>{{ Request.RoutingNumber }}</p>
                      </b-col>
                      <b-col cols="lg-4" v-if="Request.BankBIC">
                        <label>Sort/Swift (Non US)</label>
                        <p>{{ Request.BankBIC }}</p>
                      </b-col>
                      <b-col cols="lg-4" v-if="Request.BankIBAN">
                        <label>IBAN (Non US)</label>
                        <p>{{ Request.BankIBAN }}</p>
                      </b-col>
                      <b-col cols="lg-4">
                        <label>REMITTANCE ADVICE EMAIL</label>
                        <p>{{ Request.RemittanceAdviceEmail }}</p>
                      </b-col>
                      <b-col cols="lg-4">
                        <label>Bank Letter/Voided Check or Invoice Copy with ACH Info</label>
                        <upload-file-holder :Files="Request.BankLetter"></upload-file-holder>
                      </b-col>
                    </b-row>
                  </div>
                  <div class="panel-body">
                    <b-row class="panel-title">
                      <label class="col-lg-6 Intercompany-Agency">EMPLOYEE RELATIONSHIP INFO </label>
                    </b-row>
                    <b-row class="panel-content">
                      <template v-if="!IsNoneSelected">
                        <b-col cols="lg-4" v-for="item in Request.EmployeeVendorRelationship"
                          :key="item.RelationshipName">
                          <label>{{ GetRelationshipLabel(item) }}</label>
                          <div>
                            <p> {{ Request.EmployeeVendorRelationshipDetails[item.RelationshipName] }}</p>
                          </div>
                        </b-col>
                      </template>
                      <b-col cols="lg-4" v-if="IsNoneSelected">
                        <label>Employee Relationship With Vendor</label>
                        <p>None</p>
                    </b-col>
                      <b-col cols="lg-4">
                        <label>Has any form of compensation or gifts of any type been offered to you or any person
                          related to you in exchange for placing an order with this vendor?</label>
                        <p v-if="ShowCompensationDollars">YES</p>
                        <p v-else>NO</p>
                      </b-col>
                      <b-col cols="lg-4" v-if="ShowCompensationDollars">
                        <label>Compensation Received</label>
                        <p>${{ Request.CompensationReceived }}</p>
                      </b-col>
                    </b-row>
                    <b-row class="panel-content" v-if="Request.CompletedCode">
                      <b-col cols="lg-4">
                        <label>Type of Amendment / Comment</label>
                        <p>{{ Request.CompletedCode }}</p>
                      </b-col>
                    </b-row>
                  </div>
                  <div class="panel-body" v-if="VendorValidationFiles.length > 0">
                      <b-row class="panel-title">
                            <label class="col-6">Vendor Validation Attachment</label>
                      </b-row>
                      <b-row class="panel-content row">
                          <b-col :cols="ColumnsClass" v-if="VendorValidationFiles.length > 0">
                              <label>Attachment(s)</label>
                              <upload-file-holder :Files="VendorValidationFiles"></upload-file-holder>
                              </b-col>
                      </b-row>
                  </div>
                </div>
          </b-card>
      </div>
  </div>
</template>

<style scope >
@media screen and (min-width: 1200px) {
    .icreview-component {
        padding: 1rem 3rem 0px !important;
        height: 600px !important;
    }
}

.file-ref {
    color: #00a1d2 !important;
}

.Agency-Name-HFM-Co {
    font-family: "Open Sans";
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.73px;
    color: #4d5161;
}

.approval-side-section {
    background-color: rgba(162, 169, 173, 0.05);
    margin-bottom: 2px;
    padding-right: 35px !important;
    -webkit-box-shadow: 0 1px 3px 0 rgb(9 30 66 / 12%);
    box-shadow: 0 1px 3px 0 rgb(9 30 66 / 12%);
    border: solid 1px rgba(239, 242, 244, 0.2);
    border-left: none;
    border-radius: 0px 8px 8px 0px;
}

.row.page-title {
    height: 55px;
    background-color: #eef3f5 !important;
}

.row.page-title>label {
    padding: 14px 0px 14px 55px !important;
    font-family: "Open Sans";
    font-size: 20px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.81px;
    color: #4d5161;
    text-transform: none !important;
}

.file-ref {
    color: #00a1d2 !important;
}

.panel-list .panel-body .panel-title {
    border-bottom: 1px solid rgba(151, 151, 151, 0.2);
    width: 100%;
    margin-left: 0px;
    margin-bottom: 25px !important;
}

.panel-list .panel-body .panel-title label {
    color: #77a2bb;
    margin-bottom: 0px !important;
    padding-top: 13px !important;
    font-family: "Open Sans";
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.57;
    letter-spacing: normal;
    padding-left: 0px !important;
    min-height: 35px;
}

.panel-list .panel-body .panel-title .btn {
    padding-left: 5px;
    padding-right: 5px;
}

.panel-list .panel-body .panel-title>div {
    padding-right: 0px !important;
    float: right;
    font-family: "Open Sans";
    font-size: 12px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.75;
    letter-spacing: normal;
    color: #00a1d2;
}

.panel-list .panel-content label {
    text-transform: uppercase;
    color: #2c3865;
    font-size: 12px;
    margin-bottom: 1px !important;
    padding-left: 0px;
}

.panel-list .panel-content p {
    text-transform: uppercase;
    color: #4d5161;
    font-size: 14px;
    font-weight: 600;
}

.panel-content.row>div>label:first-child {
    color: #a2a9ad !important;
    font-family: "Open Sans" !important;
    font-size: 10px;
    font-weight: 600 !important;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.42;
    letter-spacing: normal;
}

.panel-content.row>div>*:not(:first-child) {
    font-family: "Open Sans" !important;
    font-size: 14px !important;
}

.panel-content.row>div>div {
    font-family: "Open Sans";
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #4d5161;
}

.panel-list>.panel-body {
    margin-top: 1rem !important;
}

.panel-content.row>div {
    margin-bottom: 10px !important;
}

.edit-icon {
    height: 11px;
    width: 11px;
    color: #00a1d2;
}

.request-status {
    margin-left: 1rem;
    margin-right: 1rem;
    display: flex;
    height: 135px;
    border-radius: 5px;
}

.status-bar {
    line-height: 2;
    color: #d5d7e0;
}

.request-status .status {
    font-size: 12px;
    font-family: "Open Sans";
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #4d5161;
}

.request-status .date {
    font-size: 10px;
    font-family: "Open Sans";
    font-weight: regular;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #a2a9ad;
}

.request-status .updatedby {
    font-size: 10px;
    font-family: "Open Sans";
    font-weight: regular;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #4d5161;
}

.request-status .comments {
    font-size: 12px;
    font-family: "Open Sans";
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #00a1d2;
}
</style>

<script>

import UploadFileHolder from '../../Common/UploadFileHolder';
export default {
  components: {
    'UploadFileHolder': UploadFileHolder
  },
  data() {
    return {
      Error: false,
      Comments: "",
      id: null,
      RelationshipVendors: [{
        "label": "NONE",
        value: "NONE"
      },
      {
        "label": "Former Employee's Name",
        value: "FormerEmployeeName"
      },
      {
        "label": "Family Member's Name",
        value: "FamilyMemberName"
      },
      {
        "label": "Friend (name)",
        value: "FriendName"
      },
      {
        "label": "Vendor has a relationship w/ Agency Client",
        value: "VendorRelationshipWithAgencyClient"
      },
      {
        "label": "Former Employee’s Name",
        value: "VendorOfFormerEmployee"
      },
      {
        "label": "Former employee’s name of Agency",
        value: "VendorWasFormerEmployeeOfAgency"
      },
      {
        "label": "Name of employee with another Agency",
        value: "VendorHasRelationshipWithAnotherAgencyEmployee"
      },
      {
        "label": "Other",
        value: "Other"
      }
      ]
    };
  },

  mounted() {
    this.id = parseInt(this.ID);
    this.GetVendorDetails();
  },
  name: "Overview",
  props: {
        Request: {
            required: false,
            type: Object,
            default() {
                return {}
            }
        },
        VendorValidationFiles: {
            required: false,
            type: Object,
            default() {
                return {}
            }
        },
        DownloadableFiles: {
            type: Boolean,
            required: false,
            default: true,
        },
        ID: {
            type: Number,
            required: false,
            default: 0,
        },
        Columns: {
            type: Number,
            default: 4,
            required: false,
        }
    },
  computed: {
    HasW9Attachment() {
      return this.Request.CurrentW9Attachment;
    },
    AttachmentLabel() {
      return this.Request.IdentificationType == 'SocialSecurityNumber' || this.Request.IdentificationType == 'USTaxID' ? 'W-9 Attachment' : 'W-8BEN/W-8BEN-E Attachment';
    },
    IsNoneSelected() {
      let result = this.Request.EmployeeVendorRelationship && this.Request.EmployeeVendorRelationship.length == 1 && this.Request.EmployeeVendorRelationship.findIndex(val => val.RelationshipName == "NONE") > -1;
      return result;
    },
    ShowCompensationDollars() {
      return this.Request.AnyGiftOrCompensationOfferedToYouToPlaceOrder == true;
    },
    ColumnsClass() {
      return `lg-${this.Columns}`;
    },
  },
  methods: {
     formatIdentityType(val) {
      if (val) {
          if (this.Request.IdentificationType == "USTaxID" && val.length > 2) {
              if (val.indexOf("-") != 2) {
                  val = val.insert(2, '-');
              }
              return val;
          } else if (this.Request.IdentificationType == "SocialSecurityNumber") {
              if (val.indexOf("-") != 3 && val.length > 3) {
                  val = val.insert(3, '-');

              }
              if (val.lastIndexOf("-") != 6 && val.length > 6) {
                  val = val.insert(6, '-');

              }
              const identificationType = val;
              const lastFourDigits = identificationType.slice(-4); // Get last four digits
              const maskedDigits = 'XXX'; // Specify the format for the first three digits
              return maskedDigits + '-XX-' + lastFourDigits;
          } else {
              return val;

          }
      } else {
          return val;
      }
    },
    GetVendorDetails() {
      this.$store
        .dispatch("prodexpamend/GetRequestWithTaskDetails", parseInt(this.id))
        .then((res) => {
          this.Request = res.data;
          let prodexpvendorInfo = Object.assign({}, this.Request);
          this.PrepareEmployeeVendorRelationship(prodexpvendorInfo);
          this.LoadParentWithVendorRequestDetails(this.Request);
          if(this.Request) {
                this.$store
                    .dispatch("vendorValidation/GetVendorValidationDetails",{
                        id : this.id,
                        vendorType:"Amend Production Expense",
                    })
                    .then((res) => {
                        if(res) {
                            const vendorID = res.data.ID;
                            this.VendorValidationFiles = res.data.Files;
                            this.LoadParentWithVendorValidationAttemptCount(res.data.AttemptCounter, vendorID);
                        }
                    })
            }
        })
        .catch((err) => {
          if (err.response && err.response.status == 403) {
            this.$router.push({
              name: "error",
              params: {
                message: "UnAuthorized"
              },
            });
          }
          else {
            this.Error = true;
            alert(err);
          }
        });
    },
    LoadParentWithVendorRequestDetails(val) {
      this.$emit("LoadProdEXPAmendDetails", val);
    },
    LoadParentWithVendorValidationAttemptCount(val, val2) {
      this.$emit("LoadVendorValidationAttemptCount", val ,val2);
    },
    PrepareEmployeeVendorRelationship(prodexpvendorInfo2) {
      if (this.IsNoneSelected) {
          let employeeRelationship = {
              ID: 0,
              RelationshipName: 'NONE'
          };
          prodexpvendorInfo2.EmployeeVendorRelationship = [];
          prodexpvendorInfo2.EmployeeVendorRelationship.push(employeeRelationship);
      } else {
          let employeeRelationships = [];

          prodexpvendorInfo2.EmployeeVendorRelationship.forEach(employeeRelations => {
              employeeRelationships.push({
                  ID: 0,
                  RelationshipName: employeeRelations
              });
          })
          prodexpvendorInfo2.EmployeeVendorRelationship = [];
          prodexpvendorInfo2.EmployeeVendorRelationship = employeeRelationships;
      }
    },
    GetRelationshipLabel(value) {
      let relationsshipIndex = this.RelationshipVendors.findIndex(val => {
        return val.value == value.RelationshipName;
      })
      if (relationsshipIndex > -1) {
        return this.RelationshipVendors[relationsshipIndex].label;
      } else {
        return "";
      }
    },
  },
};
</script>